var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 bg-white min-h-screen relative"},[_c('div',{staticClass:"hidden lg:block"},[(_vm.type === 'month')?_c('month',{attrs:{"nowDate":_vm.currentDate,"events":_vm.events},on:{"update:nowDate":function($event){_vm.currentDate=$event},"update:now-date":function($event){_vm.currentDate=$event}}}):_vm._e(),(_vm.type === 'week')?_c('week',{attrs:{"nowDate":_vm.currentDate,"events":_vm.events,"onNewEvent":_vm.onNewEvent},on:{"update:nowDate":function($event){_vm.currentDate=$event},"update:now-date":function($event){_vm.currentDate=$event}}}):_vm._e(),(_vm.type === 'day')?_c('day',{attrs:{"nowDate":_vm.currentDateTody,"events":_vm.events,"onNewEvent":_vm.onNewEvent},on:{"update:nowDate":function($event){_vm.currentDateTody=$event},"update:now-date":function($event){_vm.currentDateTody=$event}}}):_vm._e()],1),_c('mobile',{staticClass:"block lg:hidden",attrs:{"events":_vm.events,"nowDate":_vm.currentDateTody},on:{"update:nowDate":function($event){_vm.currentDateTody=$event},"update:now-date":function($event){_vm.currentDateTody=$event}}}),_c('modal',{attrs:{"is-active":_vm.modal.form,"exit-modal":function () {
        _vm.modal.form = false;
        _vm.form = {
          startAt: _vm._moment()().clone(),
          categoryId: null,
          type: null,
        };
      },"no-padding":true}},[(!_vm.form._id)?_c('div',[_c('div',{staticClass:"grid gap-0 grid-cols-2"},[_c('div',{staticClass:"\n            py-2\n            px-2\n            text-black text-sm\n            lg:text-base\n            text-center\n            cursor-pointer\n            border-0 border-b-2 border-solid\n          ",class:_vm.modal.type === 'event'
              ? 'border-dokBlue-ultra font-EffraM'
              : 'border-gray-500 font-EffraR',on:{"click":function($event){_vm.modal.type = 'event'}}},[_vm._v(" "+_vm._s(_vm.form._id ? "Mise à jour du rendez-vous" : "Créer un nouveau rendez-vous")+" ")]),_c('div',{staticClass:"\n            py-2\n            px-2\n            text-black text-sm\n            lg:text-base\n            text-center\n            cursor-pointer\n            border-0 border-b-2 border-solid\n          ",class:_vm.modal.type === 'block_event'
              ? 'border-dokBlue-ultra font-EffraM'
              : 'border-gray-500 font-EffraR',on:{"click":function($event){_vm.modal.type = 'block_event'}}},[_vm._v(" Bloquer un créneau horaire ")])])]):_vm._e(),_c('div',[(_vm.modal.type === 'event')?_c('form-create-or-update',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}):_vm._e(),(_vm.modal.type === 'block_event')?_c('form-block-slots',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }