<template>
  <div class="py-2 bg-white min-h-screen relative">
    <!-- Type Agenda -->
    <div class="hidden lg:block">
      <month
        :nowDate.sync="currentDate"
        :events="events"
        v-if="type === 'month'"
      />
      <week
        :nowDate.sync="currentDate"
        :events="events"
        :onNewEvent="onNewEvent"
        v-if="type === 'week'"
      />
      <day
        :nowDate.sync="currentDateTody"
        :events="events"
        :onNewEvent="onNewEvent"
        v-if="type === 'day'"
      />
    </div>

    <mobile
      :events="events"
      :nowDate.sync="currentDateTody"
      class="block lg:hidden"
    />

    <!-- Modal Form Create Or Update Appointment -->
    <modal
      :is-active="modal.form"
      :exit-modal="
        () => {
          modal.form = false;
          form = {
            startAt: _moment()().clone(),
            categoryId: null,
            type: null,
          };
        }
      "
      :no-padding="true"
    >
      <div v-if="!form._id">
        <div class="grid gap-0 grid-cols-2">
          <div
            class="
              py-2
              px-2
              text-black text-sm
              lg:text-base
              text-center
              cursor-pointer
              border-0 border-b-2 border-solid
            "
            :class="
              modal.type === 'event'
                ? 'border-dokBlue-ultra font-EffraM'
                : 'border-gray-500 font-EffraR'
            "
            @click="modal.type = 'event'"
          >
            {{
              form._id
                ? "Mise à jour du rendez-vous"
                : "Créer un nouveau rendez-vous"
            }}
          </div>
          <div
            class="
              py-2
              px-2
              text-black text-sm
              lg:text-base
              text-center
              cursor-pointer
              border-0 border-b-2 border-solid
            "
            :class="
              modal.type === 'block_event'
                ? 'border-dokBlue-ultra font-EffraM'
                : 'border-gray-500 font-EffraR'
            "
            @click="modal.type = 'block_event'"
          >
            Bloquer un créneau horaire
          </div>
        </div>
      </div>
      <div>
        <form-create-or-update
          :form.sync="form"
          v-if="modal.type === 'event'"
        />
        <form-block-slots
          :form.sync="form"
          v-if="modal.type === 'block_event'"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { each, find, filter } from "lodash";
import moment from "moment";
const modal = () => import("@/views/global-components/dragModal");

//layouts
const week = () => import("./agendaType/week/index");
const month = () => import("./agendaType/month/index");
const day = () => import("./agendaType/day/index");
const mobile = () => import("./agendaType/mobile/index");

// Forms
const formCreateOrUpdate = () => import("./layouts/form/index");
const formBlockSlots = () => import("./layouts/form_block_slot/index");

//Loader
const loader = () => import("@/views/global-components/loader");

// Navbar
const navbarDok = () => import("@/layouts/components/navbar/index");

export default {
  data() {
    return {
      currentDate: moment().clone().day("Lundi"),
      currentDateTody: moment().startOf("day"),
      loader: { events: false, slots: false, categorys: false },
      form: {
        startAt: moment().clone(),
        categoryId: null,
        type: null,
        fromHour: {},
        toHour: {},
      },
      modal: {
        type: "event", // Event & Block_Event
        form: false,
      },
      widthScreen: screen.width,
    };
  },
  computed: {
    PickerDate() {
      return this.$store.state.agenda.pickerDate;
    },
    type: {
      get() {
        return this.$store.state.agenda.typeAgenda;
      },
      set(view) {
        this.$store.commit("agenda/SET_VIEW", view);
      },
    },
    events: {
      get() {
        return this.$store.state.agenda.eventsAgenda;
      },
      set(view) {
        this.$store.commit("agenda/SET_EVENTS", view);
      },
    },
  },
  watch: {
    PickerDate: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        let formatedByMoment = moment(this.PickerDate).clone();
        if (this.type === "day") {
          this.currentDateTody = formatedByMoment;
        } else {
          this.currentDate = formatedByMoment;
        }
        this.getAllEvents();
      }
    },
    type: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAllEvents();
      }
    },
  },
  beforeMount() {
    if (this.widthScreen <= 760) {
      this.type = "day";
    }
  },
  mounted() {
    //Emit Last Form
    EventBus.$on("BACK_TO_OLD_FORM", () => {
      this.form.startAt = moment().clone();
    });

    // HelthBook Approvie
    EventBus.$on("health-book-notification", (patientId) => {
      let eventsByPatient = this.events.filter((item) => {
        return item.patientId === patientId;
      });

      each(eventsByPatient, function (entry) {
        entry.doctor_has_access_helthbook = "AUTHORIZED";
      });
    });

    // Push New Event After ACCEPTED
    EventBus.$on("Push-New-Event-Agenda", (data) => {
      if (data._id) {
        this.events.push(data);
      }
    });

    // Listen For Close Modal
    EventBus.$on("Modal_Close", (refreshEvents = false) => {
      this.form = {
        startAt: moment().clone(),
        categoryId: null,
        type: null,
      };
      this.modal.form = false;
      if (refreshEvents) this.getAllEvents();
    });

    // Listen For Modal
    EventBus.$on("Modal_Create_Appointment", (currentDate) => {
      this.modal.form = true;
    });

    // Listen For New Appointment
    EventBus.$on("Modal_Has_New_Success_Appointment", (data) => {
      if (this.$route.name === "agenda") {
        this.events.push(data);
        this.form = {
          startAt: moment().clone(),
          categoryId: null,
          type: null,
        };
        this.modal.form = false;
        this.$forceUpdate();
      }
    });

    // Listen For Update Appointment
    EventBus.$on("Modal_Has_Update_Success_Appointment", (data) => {
      let eventAppointment = find(
        this.events,
        (event) => event._id === data._id
      );
      this.form = {
        startAt: moment().clone(),
        categoryId: null,
        type: null,
      };
      this.modal.form = false;
    });

    // Listen For Click event
    EventBus.$on("CLICK_EVENT", (data) => {
      this.form = data;
      this.modal.type = "event";
      this.modal.form = true;
      this.$forceUpdate();
    });

    // Listen for delete event
    EventBus.$on("EVENT_DELETE", (data) => {
      this.events = filter(this.events, (event) => event._id !== data);
      this.form = {
        startAt: moment().clone(),
        categoryId: null,
        type: null,
      };
      this.modal.form = false;
    });

    // Listen for agenda mobile Next Or previous*
    EventBus.$on("Agenda_Mobile_Day", (type) => {
      return type.action === "next" ? this.nextDate() : this.previousDate();
    });

    // Next & Pre
    EventBus.$on("TypeToDate", (type) => {
      if (type === "next") {
        this.nextDate();
      }

      if (type === "previous") {
        this.previousDate();
      }
    });

    // Push New Event
    EventBus.$on("Push_New_Appointment_Object", (object) => {
      this.events.push(object);
    });

    // Get Today Agenda
    EventBus.$on("onTodayClick", () => {
      this.currentDate = moment().clone().day("Lundi");
      this.currentDateTody = moment().startOf("day");
      this.getAllEvents();
      EventBus.$emit("generationDaysMonth");
    });

    // Propos Event Date
    EventBus.$on("Propos_Date_Event", (data) => {
      let event = find(this.events, (event) => {
        return event._id === data.id;
      });

      if (event) {
        event.startAt = data.date;
      }
    });
  },
  async created() {
    this.getAllEvents();
    this.getAllSlots();
    this.getAllMotifs();
  },
  methods: {
    async getAllEvents() {
      this.loader.events = false;
      this.$vs.loading();
      const { data, ok } = await this.$store.dispatch("agenda/INDEX_EVENTS", {
        view: this.type,
        start:
          this.type !== "day"
            ? this.currentDate.toISOString()
            : this.currentDateTody.toISOString(),
      });
      if (ok) {
        this.events = data;
        this.loader.events = true;
        this.$vs.loading.close();
      }
    },
    async getAllSlots() {
      this.loader.slots = false;
      await this.$store.dispatch("agenda/GET_SLOTS", {
        onData: (data) => {
          if (data.ok) {
            this.loader.slots = true;
          }
        },
      });
    },
    async getAllMotifs() {
      this.loader.categorys = false;
      await this.$store.dispatch("agenda/GET_DOCTOR_CATEGORIES", {
        onData: (data) => {
          if (data.ok) {
            this.loader.categorys = true;
          }
        },
      });
    },
    _moment() {
      return moment;
    },
    onNewEvent(date) {
      let startDate = moment(date, "DD/MM/YYYY HH:mm");
      this.form.startAt = startDate;
      this.modal.form = true;
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "APPOINTEMENT",
          action: "NEW",
          data: {},
        },
      });
    },
    previousDate() {
      if (this.type === "week")
        this.currentDate = this.currentDate.clone().subtract(1, "week");

      if (this.type === "month") {
        this.currentDate = this.currentDate.clone().subtract(1, "months");
      }

      if (this.type === "day") {
        this.currentDateTody = this.currentDateTody.clone().subtract(1, "day");
      }

      return this.getAllEvents();
    },
    nextDate() {
      if (this.type === "week") {
        this.currentDate = this.currentDate.clone().add(1, "week");
        EventBus.$emit("nextWeek");
        this.$forceUpdate();
      }
      if (this.type === "month") {
        this.currentDate = this.currentDate.clone().add(1, "months");
      }
      if (this.type === "day") {
        this.currentDateTody = this.currentDateTody.clone().add(1, "day");
      }

      return this.getAllEvents();
    },
  },
  components: {
    formCreateOrUpdate,
    formBlockSlots,
    navbarDok,
    modal,
    loader,
    month,
    week,
    day,
    mobile,
  },
};
</script>
